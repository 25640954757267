<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>    
    <div>
        <p-card>
            <h3 style="text-align: center;">Situação da Conta</h3>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-1">
                    <p-card>
                        <label style="text-align: center;">Ativo</label>
                    </p-card>
                </div>
                <div class="p-fluid p-formgrid p-grid" style="margin-top: 10px;">
                    <div class="p-col-6">
                        <label>Inicio Contrato:</label>
                        <span>15/07/2022</span>
                    </div>
                    &nbsp;
                    <div class="p-col-7">
                        <label>Data Desativação:</label>
                        <span>15/07/2022</span>
                    </div>
                </div>
            </div>

            <!-- <div style="margin-top: 50px;">
                <app-footer-page [end]="true" (confirmar)="processarFormulario()" (cancelar)="cancelarLocal()"
                    labelConfirmar="Gravar"></app-footer-page>
            </div> -->

        </p-card>
    </div>
</ng-template>
<p-toast position="bottom-right"></p-toast>