<p-card [header]="'Usuarios'">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-8">
            <div class="p-inputgroup">
                <input #inputPesquisa appUppercaseNotForm (keypress)="pressionaEnter($event)" pInputText>
                <button pButton type="button" icon="fa fa-search" (click)="carregarLista()"
                        ></button>
            </div>
        </div>
        <!-- <div class="p-field p-col-12 p-md-2" style="position: relative;">
            <app-select #selectValue [options]="selectSort"></app-select>
        </div> -->
        <div class="p-field p-offset-2 p-col-12 p-md-2">
            <button pButton label="Cadastrar" (click)="registration()"></button>
            
        </div>
    </div>

    <p-table styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm" [paginator]="true" [(rows)]="qtdLinhas" [totalRecords]="totalItens" [value]="lista" [lazy]="true"  (onLazyLoad)="lazyLoad($event)" [rowsPerPageOptions]="opcoesLinhas">
        <ng-template pTemplate="header" let-columns>
            <tr>
            <th [ngStyle]="{width: '22%'}"> Nome </th>            
            <th [ngStyle]="{width: '10%'}"> Departamento </th>
            <th [ngStyle]="{width: '20%'}"> Email </th>
            <th [ngStyle]="{width: '10%'}"> Celular </th>
            <th [ngStyle]="{width: '10%'}"> Perfil </th>
            <th [ngStyle]="{width: '5%'}"> Ativo </th>
            <th [ngStyle]="{width: '13%'}"> Cpf / Cnpj </th>
            <th [ngStyle]="{width: '5%'}"> Opções </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
            <td><span (click)="linkPessoa(rowData)" class="link-pessoa">{{rowData.nome}}</span></td>
            <td>{{rowData.departamento_nome}}</td>
            <td>{{rowData.email}}</td>
            <td>{{rowData.phone_cell_format}}</td>
            <td>{{rowData.perfil}}</td>
            <td appAlignCenter>{{getActive(rowData.active)}}</td>
            <td>{{rowData.cpf_cnpj | cpfCnpj}}</td>
            <td><app-opcoes-table [value]="rowData"
                                  [opcoes]="opcoesTable"></app-opcoes-table></td>
            </tr>
        </ng-template>
    </p-table>

</p-card>

<p-confirmDialog header="Confirmacao" icon="pi pi-exclamation-triangle" width="425"></p-confirmDialog>
<p-toast position="bottom-right"></p-toast>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #registrationUser></button>
</div>

<app-modal [styleScroll]="{width: '100%', height: '450px'}" [fundo]="true" *appModalOpenOnClick="[registrationUser, '1Acd5WGj70']" [hash]="'1Acd5WGj70'">
    <app-modal-user-registration #content [hash]="'1Acd5WGj70'"></app-modal-user-registration>
</app-modal>

<!-- <app-modal-cadastrar-pessoa [modalVisible]="modalCadastrarPessoa" #content (closeModal)="modalCadastrarPessoa = false"></app-modal-cadastrar-pessoa> -->