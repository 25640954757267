<ng-template #conteudo>
    <app-page-divided [padrao]="false">
        <div principal [formGroup]="form">
            <div>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-col-4">
                        <app-date #dataSelect label="Data" formControlName="DateMovement"></app-date>
                    </div>
                    <div class="p-col-8">
                        <app-input-financial-category-search [label]="'Natureza Financeira'"
                            (valorSelecionado)="selecionouNatureza($event)" formControlName="FinancialCategoryId">
                        </app-input-financial-category-search>
                    </div>
                </div>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-col-12">
                        <app-input-search-person [label]="'Pessoa'" (valorSelecionado)="selecionouPerson($event)"
                            formControlName="PersonId"></app-input-search-person>
                    </div>
                </div>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-col-7">
                        <app-input [label]="'Histórico'" formControlName="Historic"></app-input>
                    </div>
                    <div class="p-col-3">
                        <app-input-money [label]="'Valor'" formControlName="Amount"></app-input-money>
                    </div>
                    <div class="p-col-2" style="margin-top: 25px;">
                        <p-checkbox binary="true" label="Reconciliado" formControlName="Reconciled"></p-checkbox>
                    </div>
                </div>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-col-3">
                        <app-select [label]="'Espécie'" [options]="selectSpecie" formControlName="Specie"></app-select>
                    </div>
                    <div class="p-col-3">
                        <app-select [label]="'Conta'" [options]="selecAccount" formControlName="AccountId"></app-select>
                    </div>
                    <div class="p-col-6">
                        <app-input [label]="'Obs.'" formControlName="Obs"></app-input>
                    </div>
                </div>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    
                </div>
            </div>
            <div style="margin-top: 50px;">
                <app-footer-page [end]="true" (confirmar)="processarFormulario()" (cancelar)="fecharModal()"
                    labelConfirmar="Salvar"></app-footer-page>
            </div>
        </div>
    </app-page-divided>
</ng-template>