<ng-template #conteudo>
    <app-page-divided [padrao]="false">
        <div principal [formGroup]="form">
            <div>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-col-3">
                        <app-date #dataSelect label="Data de Fechamento" formControlName="DateRegister"></app-date>
                    </div>
                    <div class="p-col-3">
                        <app-date #dataSelect label="Data de Atualização" formControlName="DateUpdate"></app-date>
                    </div>
                    <div class="p-offset-3 p-col-3">
                        <app-input-money [label]="'Limite de Crédito'" formControlName="CreditLimit"></app-input-money>
                    </div>
                </div>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-3">
                        <app-select [label]="'Banco'" [options]="selectbank" formControlName="BankCode"></app-select>
                    </div>
                    <!-- <div class="p-field p-col-12 p-md-3">
                        <app-select [label]="'Categoria Financeira'" [options]="selectCategory" formControlName="FinancialCategoryId"></app-select>
                    </div> -->
                    <div class="p-field p-col-12 p-md-3">
                        <app-select [label]="'Tipo de Conta'" [options]="selectTypePro" formControlName="TypePro"></app-select>
                    </div>
                    <div class="p-offset-3 p-field p-col-12 p-md-3">
                        <app-input-integer [label]="'Codigo de Plano de Conta'" formControlName="CodePlanAccount"></app-input-integer>
                    </div>
                </div>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-3">
                        <app-input [label]="'Agência'" formControlName="BankAgency"></app-input>
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <app-input [label]="'Conta'" formControlName="AccountNumber"></app-input>
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <app-input [label]="'Digito'" formControlName="DigitNumber"></app-input>
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <app-input [label]="'Nome'" formControlName="Name"></app-input>
                    </div>
                </div>
                
            </div>
            <div style="margin-top: 50px;">
                <app-footer-page [end]="true" (confirmar)="processarFormulario()" (cancelar)="fecharModal()"
                    labelConfirmar="Salvar"></app-footer-page>
            </div>
        </div>
    </app-page-divided>
</ng-template>