<p-card [header]="'Demostrativo do Fluxo de Caixa'">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-formgrid p-grid p-col-4">
            <div class="p-col-5">
                <app-date #dataInicial [(ngModel)]="dataInit"></app-date>
            </div>
            <div class="p-col-5">
                <app-date #dataFinal [(ngModel)]="dataFim"></app-date>
            </div>
        </div>
        <div class="p-col-2" style="margin-top: 5px; margin-left: -65px;">
            <app-date-navigator (alterouData)="alterouData($event)"></app-date-navigator>
        </div>
        <div class="p-field p-col-12 p-md-2" style="position: relative; margin-left: 50px;">
            <button pButton label="Atualizar" (click)="loaddata()"></button>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <i class="pi pi-file-excel" style="font-size: 2rem; margin-left: 95px; cursor: pointer;"
                (click)="report('xls')"></i>
            <i class="pi pi-file-pdf" style="font-size: 2rem; margin-left: 25px; cursor: pointer"
                (click)="report('pdf')"></i>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <p-splitButton label="Relatórios" [model]="itemsReport"></p-splitButton>
        </div>
    </div>
</p-card>

<p-confirmDialog header="Confirmacao" icon="pi pi-exclamation-triangle" width="425"></p-confirmDialog>
<p-toast position="bottom-right"></p-toast>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #simpleRegistration></button>
</div>
&nbsp;
<div class="p-col-12">
    <div class="p-offset-2 p-col-6">
        <p-card>
            <!-- <div class="vertical">

            </div> -->
            <div class="p-col-12">
                <hr />

                <div class="card-resumo-item">
                    <div appAlignCenter class="p-col-6">
                        <span>Atividades Operacionais</span>
                    </div>
                </div>

                <hr />

                 <div class="card-resumo-item">
                    <div class="p-col-6">
                        <span>Recebimentos</span>
                    </div>
                </div>



                <div class="card-item">
                    <div appAlignCenter class="p-col-6">
                        <span>Receitas</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(Income)">R$: {{Income | virgulaPipe}}</span>
                    </div>
                </div>

                <div class="card-resumo-item">
                    <div class="p-col-6">
                        <span>Pagamentos</span>
                    </div>
                </div>

                <div class="card-item">
                    <div appAlignCenter class="p-col-6">
                        <span>Deduções da Receita</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(Deduction)">R$: {{Deduction | virgulaPipe}}</span>
                    </div>
                </div>

                <hr />

                <div class="card-item">
                    <div appAlignCenter class="p-col-6">
                        <span>Custos e Estoques</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(CostOfSold)">R$: {{CostOfSold | virgulaPipe}}</span>
                    </div>
                </div>

                <div class="card-item">
                    <div appAlignCenter class="p-col-6">
                        <span>Despesas Variaveis Venda</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(VariableExpense)">R$: {{VariableExpense | virgulaPipe}}</span>
                    </div>
                </div>

                <div class="card-item">
                    <div appAlignCenter class="p-col-6">
                        <span>Despesas Operacionais</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(OperationalExpense)">R$: {{OperationalExpense | virgulaPipe}}</span>
                    </div>
                </div>

                <div class="card-item">
                    <div appAlignCenter class="p-col-6">
                        <span>Despesas com Sócios</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(ParthnerExpense)">R$: {{ParthnerExpense | virgulaPipe}}</span>
                    </div>
                </div>

                <hr />

                <div class="card-resumo-item">
                    <div class="p-col-6">
                        <span>Geração de Caixa Operacional</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(OperationalFlow)">R$: {{OperationalFlow | virgulaPipe}}</span>
                    </div>
                </div>

                <hr />
                &nbsp;

                <div class="card-resumo-item">
                    <div appAlignLeft class="p-col-6">
                        <span>Atividades de Investimento</span>
                    </div>
                </div>

                <div class="card-item">
                    <div appAlignCenter class="p-col-6">
                        <span>Saldo dos Investimentos</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(InvestmentFlow)">R$: {{InvestmentFlow | virgulaPipe}}</span>
                    </div>
                </div>

                <hr />
                &nbsp;

                <div class="card-resumo-item">
                    <div appAlignLeft class="p-col-6">
                        <span>Atividades de Financiamento</span>
                    </div>
                </div>

                <div class="card-item">
                    <div appAlignCenter  class="p-col-6">
                        <span>Saldo dos Financiamentos</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(FinancingFlow)">R$: {{FinancingFlow | virgulaPipe}}</span>
                    </div>
                </div>

                <hr />

                <div class="card-resumo-item">
                    <div class="p-col-6">
                        <span>Variação de Caixa</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(TotalCashFlow)">R$: {{TotalCashFlow | virgulaPipe}}</span>
                    </div>
                </div>

                <div class="card-item">
                    <div appAlignCenter class="p-col-6">
                        <span>Saldo Inicial</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(InicialBalance)">R$: {{InicialBalance | virgulaPipe}}</span>
                    </div>
                </div>

                <hr />

                <div class="card-resumo-item">
                    <div class="p-col-6">
                        <span>Saldo Final de Caixa</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(FinalBalance)">R$: {{FinalBalance | virgulaPipe}}</span>
                    </div>
                </div>

                <hr />


            </div>
        </p-card>
    </div>
</div>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #modalreportfluxo></button>
</div>


<app-modal [styleScroll]="{width: '100%', height: '150px'}" [fundo]="true" *appModalOpenOnClick="[modalreportfluxo, '1Acd5WGj5']" [hash]="'1Acd5WGj5'">
    <app-modal-report-cash-flow #content [dateStart]="dateStart" [dateEnd]="dateEnd" [endpoint]="endpoint" [nameFile]="nameFile" [title]="title" [hash]="'1Acd5WGj5'"></app-modal-report-cash-flow>
</app-modal>
