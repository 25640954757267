<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>
    <div [formGroup]="form">
        <p-card [header]="'Dados da Contabilidade'">
            <div style="margin-left: 5px;" class="p-fluid p-formgrid p-grid">
                <!-- <h1 style="font-size: 18px;margin-right:10px;">Dados da Contabilidade</h1> -->
                <div class="p-field p-col-4 p-md-3">
                    <app-input-cpf-cnpj [label]="'CNPJ'" formControlName="Cnpj"></app-input-cpf-cnpj>
                </div>
                <div class="p-field p-col-4 p-md-3" style="margin-top: 1.5rem;">
                    <button pButton label="Buscar CNPJ" (click)="takeCnpj()"></button>
                </div>
            </div>

            <app-page-divided>
                <div principal class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6 p-md-6">
                        <app-input [label]="'Razão Social'" formControlName="Nome"></app-input>
                    </div>
                    <div class="p-field p-col-6 p-md-6">
                        <app-input [label]="'Nome fantasia'" formControlName="Fantasia"></app-input>
                    </div>
                    <div class="p-field p-col-4 p-md-4">
                        <app-input-telefone [label]="'Telefone'" formControlName="Phone1"></app-input-telefone>
                    </div>
                    <div class="p-field p-col-4 p-md-4">
                        <app-input-telefone [label]="'Celular'" formControlName="Cel"></app-input-telefone>
                    </div>
                    <div class="p-field p-col-4 p-md-4">
                        <app-input [label]="'Contato'" formControlName="Contato"></app-input>
                    </div>
                </div>
                <div secundario class="p-col-12" style="margin-left: 70px;">
                    <p-card (click)="clickUploadImagem()" [style]="{marginTop: '10px', width: '150px', height: '120px', cursor: 'pointer'}">
                        <!-- <app-image-upload formControlName="CaminhoFoto" [image]="'../../../assets/images/user.png'"></app-image-upload> -->
                        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                            [containWithinAspectRatio]="true" [aspectRatio]="2 / 2" [resizeToWidth]="640"
                            [style.display]="showCropper ? null : 'none'" format="png"
                            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                            (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"
                            [onlyScaleDown]="true" [roundCropper]="false"></image-cropper>
                        <div *ngIf="!imageChangedEvent" class="img-container">
                            <img [src]="imagem" alt="">
                        </div>
                        <input type="file" (change)="fileChangeEvent($event)" #uploadImagem class="esconder" />
                        <!-- <button pButton label="Upload" (click)="clickUploadImagem()"></button> -->
                    </p-card>
                </div>

            </app-page-divided>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-3 p-md-3" style="margin-left: 7px;">
                        <div>Cep</div>
                        <div style="display: flex;justify-content: flex-start;">
                            <div class="p-col-10" style="padding-right: 0px;padding-left: 0px;">
                                <p-inputMask id="cep" formControlName="Cep" (onBlur)="verificaCepValido($event)"
                                    mask="99999-999"></p-inputMask>
                            </div>
                            <button pButton type="button" icon="fa fa-search" (click)="modalCepVisible = true"></button>
                        </div>
                    </div>
                    <div class="p-field p-col-3 p-md-3">
                        <app-input [label]="'Número'" formControlName="Numero"></app-input>
                    </div>
                    <div class="p-field p-col-6 p-md-6" style="margin-left: -7px;">
                        <app-input [label]="'Logradouro'" formControlName="Logradouro"></app-input>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-4 p-md-4">
                        <app-input [label]="'Bairro'" formControlName="Bairro"></app-input>
                    </div>
                    <div class="p-field p-col-8 p-md-8">
                        <app-input [label]="'Complemento'" formControlName="Complemento"></app-input>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-4 p-md-4">
                        <app-select [label]="'Estado'" [options]="selectEstado" formControlName="Uf"></app-select>
                    </div>
                    <div class="p-field p-col-8 p-md-8">
                        <app-input [label]="'Cidade'" formControlName="Cidade"></app-input>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12" style="margin-top: 10px;">
                        <app-footer-page [end]="true" [tamanhoConfirma]="'p-col-2'" [tamanhoCancelar]="'p-col-2'"
                            (confirmar)="processarFormulario()" (cancelar)="cancelarLocal()"
                            [labelConfirmar]="'Salvar'">
                        </app-footer-page>
                    </div>
                </div>
        </p-card>
    </div>

</ng-template>
<p-toast position="bottom-right"></p-toast>
