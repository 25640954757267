<div class="p-fluid p-formgrid p-grid">
    <div class="p-col-1 p-offset-8" style="font-size: 18px; margin-right: -20px; margin-top: 5px;">
        <i class="pi pi-file-excel" style="font-size: 2rem; cursor: pointer; margin-right: 20px;" (click)="report('xls')"></i>
        <i class="pi pi-file-pdf" style="font-size: 2rem; cursor: pointer" (click)="report('pdf')"></i>
    </div>
    <div class="p-col-2">
        <button pButton label="Processar Conciliação" (click)="processConciliation()"></button>
    </div>
</div>

<div class="p-col-12 ui-g-nopad">
    <!-- *ngFor="let item of lista; let i = index;" -->
    <div style="margin-bottom: 40px;" *ngFor="let item of lista; let i = index;">
        <app-reconciled-transfer [data]="item" [optionsConta]="selectAccount"
                                      [optionsNaturezaFinanceira]="selectNaturezaFinanceira"
                                      type="extratoNaoConciliado" [dataPesquisa]="dataPesquisa"
                                      (recarregarDados)="removeItem(i)"></app-reconciled-transfer>
    </div>    
</div>

<div style="min-width: 90vw; display: flex; justify-content: center;">
    <div class="p-col-2" style="margin: 30px 0;">
        <button *ngIf="lista.length < totalItens" pButton label="Carregar Mais" (click)="loadData(1)"></button>
    </div>
</div>
