<p-card [header]="'Faturas'">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-8">
            <div class="p-inputgroup">
                <input #inputPesquisa appUppercaseNotForm (keypress)="pressionaEnter($event)" pInputText>
                <button pButton type="button" icon="fa fa-search" (click)="carregarLista()"
                        ></button>
            </div>
        </div>
       
        <div class="p-field p-offset-2 p-col-12 p-md-2">
            <button pButton label="Cadastrar" ></button>
            <!-- (click)="navegar()" -->
        </div>
    </div>

    <p-table styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm" [paginator]="true" [(rows)]="qtdLinhas" [totalRecords]="totalItens" [value]="lista"  (onLazyLoad)="lazyLoad($event)" [rowsPerPageOptions]="opcoesLinhas">
        <ng-template pTemplate="header" let-columns>
            <tr>
            <th [ngStyle]="{width: '20%'}"> Mês de Uso </th>
            <th [ngStyle]="{width: '20%'}"> Conexões </th>
            <th [ngStyle]="{width: '10%'}"> Nº de Empresas </th>
            <th [ngStyle]="{width: '10%'}"> Valor </th>
            <th [ngStyle]="{width: '10%'}"> Vencimento </th>
            <th [ngStyle]="{width: '10%'}"> Situação </th>
            <th [ngStyle]="{width: '5%'}"> Opções </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
            <td><span (click)="linkPessoa(rowData)" class="link-pessoa">{{rowData.nome}}</span></td>
            <td>{{rowData.MesUso}}</td>
            <td>{{rowData.Conexoes}}</td>
            <td>{{rowData.Empresas}}</td>
            <td>{{rowData.Valor | virgulaPipe}}</td>
            <td>{{rowData.Vencimento | date}}</td>
            <td>{{rowData.Situacao}}</td>
            <td><app-opcoes-table [value]="rowData"
                                  [opcoes]="opcoesTable"></app-opcoes-table></td>
            </tr>
        </ng-template>
    </p-table>

</p-card>

<p-confirmDialog header="Confirmacao" icon="pi pi-exclamation-triangle" width="425"></p-confirmDialog>
<p-toast position="bottom-right"></p-toast>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #cadastrarPessoa></button>
</div>

<!-- <app-modal [fundo]="true" *appModalOpenOnClick="[cadastrarPessoa, '1Acd5WGj70']" [hash]="'1Acd5WGj70'">
    <app-modal-cadastrar-pessoa #content></app-modal-cadastrar-pessoa>
</app-modal> -->

<!-- <app-modal-cadastrar-pessoa [modalVisible]="modalCadastrarPessoa" #content (closeModal)="modalCadastrarPessoa = false"></app-modal-cadastrar-pessoa> -->