<div class="topbar clearfix" style="background-color: #fff;">
    <div class="topbar-left" [routerLink]="home" style="background-color: #fff;">
        <div class="logo"></div>
    </div>

    <div class="topbar-right" style="display: flex; justify-content: space-between;">
        <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
            <i class="pi pi-angle-left"></i>
        </a>

        <!-- <a id="rightpanel-menu-button" href="#" (click)="app.onRightPanelButtonClick($event)">
            <i class="pi pi-ellipsis-v"></i>
        </a> -->

        <a id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
            <i class="pi pi-bars"></i>
        </a>

        <ul class="topbar-items animated fadeInDown" [ngClass]="{'topbar-items-visible': app.topbarMenuActive}">

            <div style="width: 250px;
                    display: inline-block;
                    font-size: 14px;
                    color: #4D4D4D;
                    text-transform: uppercase;
                    vertical-align: middle;
                    font-weight: bold;
                    margin-right: 450px;
                    margin-top: 10px">
           <span class="mes-label">{{labelEnvironment}}</span>
        </div>
            <!-- <li #profile class="profile-item" *ngIf="app.profileMode==='top'||app.isHorizontal()"
                [ngClass]="{'active-top-menu':app.activeTopbarItem === profile}">

                <a href="#" (click)="app.onTopbarItemClick($event,profile)">
                    <img class="profile-image" src="assets/layout/images/avatar.png" />
                    <span class="topbar-item-name">Jane Williams</span>
                </a>

                <ul class="ultima-menu animated fadeInDown">
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-user"></i>
                            <span>Profile</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-lock"></i>
                            <span>Privacy</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-cog"></i>
                            <span>Settings</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-power-off"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </li> -->
            <li nfI #settings [ngClass]="{'active-top-menu':app.activeTopbarItem === settings}">
                <a href="#" (click)="app.onTopbarItemClick($event,settings)">
                    <i style="color: #4D4D4D;" class="topbar-icon pi pi-cog"></i>
                    <span class="topbar-item-name">Settings</span>
                </a>
                <ul class="ultima-menu animated fadeInDown">
                    <li *ngIf="counter === 'true'" role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event, 'settings/parameters')">
                            <i class="pi pi-cog"></i>
                            <span>Configuração</span>
                        </a>
                    </li>
                    <li *ngIf="counter === 'true'" role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event, 'settings')">
                            <i class="pi pi-briefcase"></i>
                            <span>Dados da Contabilidade</span>
                        </a>
                    </li>
                    <li *ngIf="counter === 'true'" role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event, 'development')">
                            <i class="pi pi-user-plus"></i>
                            <span>Incluir Contratante</span>
                        </a>
                    </li>
                    <li *ngIf="counter === 'true'" role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event, 'settings/users-list')">
                            <i class="pi pi-users"></i>
                            <span>Usuarios</span>
                        </a>
                    </li>
                    <li *ngIf="counter === 'true'" role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-upload"></i>
                            <span>Exportação</span>
                        </a>
                    </li>
                    <li *ngIf="counter === 'true'" role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event, 'settings/invoices-list')">
                            <i class="pi pi-dollar"></i>
                            <span>Faturas</span>
                        </a>
                    </li>
                    <li *ngIf="counter === 'true'" role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event, 'settings/support')">
                            <i class="pi pi-user-edit"></i>
                            <span>Suporte</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="deslogar()">
                            <i class="pi pi-power-off"></i>
                            <span>Sair</span>
                        </a>
                    </li>
                </ul>
            </li>
            <!-- <li #messages [ngClass]="{'active-top-menu':app.activeTopbarItem === messages}">
                <a href="#" (click)="app.onTopbarItemClick($event,messages)">
                    <i class="topbar-icon pi pi-envelope animated swing"></i>
                    <span class="topbar-badge animated rubberBand">5</span>
                    <span class="topbar-item-name">Messages</span>
                </a>
                <ul class="ultima-menu animated fadeInDown">
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar1.png" width="35"/>
                            <span>Give me a call</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar2.png" width="35"/>
                            <span>Sales reports attached</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar3.png" width="35"/>
                            <span>About your invoice</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar2.png" width="35"/>
                            <span>Meeting today at 10pm</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar4.png" width="35"/>
                            <span>Out of office</span>
                        </a>
                    </li>
                </ul>
            </li> -->
            <!-- <li #notifications [ngClass]="{'active-top-menu':app.activeTopbarItem === notifications}">
                <a href="#" (click)="app.onTopbarItemClick($event,notifications)">
                    <i class="topbar-icon pi pi-bell"></i>
                    <span class="topbar-badge animated rubberBand">4</span>
                    <span class="topbar-item-name">Notifications</span>
                </a>
                <ul class="ultima-menu animated fadeInDown">
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-sliders-h"></i>
                            <span>Pending tasks</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-calendar"></i>
                            <span>Meeting today at 3pm</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-download"></i>
                            <span>Download documents</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-ticket"></i>
                            <span>Book flight</span>
                        </a>
                    </li>
                </ul>
            </li> -->
            <li style="margin-top: 0px;">
                <span
                    *ngIf="counter !== 'true'"
                    (click)="abrirModalTrocarEmpresa()"
                    class="link-pessoa"
                    style="
                    font-size: 13px;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    text-transform: uppercase;
                    color: #4D4D4D;
                    "
                >Empresa:</span><br>
                <span *ngIf="counter !== 'true'" (click)="abrirModalTrocarEmpresa()" class="link-pessoa" style="color: #4D4D4D;">{{empresa}}</span>
                <button *ngIf="counter === 'true'" pButton type="button" class="trocar" style="background: #3B4CC0; margin-top: -20px; margin-right: 20px;" label="Selecionar Empresa" (click)="abrirModalTrocarEmpresa()"></button>
            </li>
            <!-- <li #search class="search-item" [ngClass]="{'active-top-menu':app.activeTopbarItem === search}"
                (click)="app.onTopbarItemClick($event,search)">
                <span class="p-input-icon-left">
                    <i class="topbar-icon pi pi-search"></i>
                    <input type="text" pInputText placeholder="Search">
                </span>
            </li> -->
        </ul>
    </div>
</div>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #trocarempresa></button>
</div>

<app-modal [fundo]="true" *appModalOpenOnClick="[trocarempresa, '1Acd5WGj78']" [hash]="'1Acd5WGj78'">
    <app-modal-trocar-empresa #content (closeModal)="modalFechado()" [data]="'1Acd5WGj78'" ></app-modal-trocar-empresa>
</app-modal>
