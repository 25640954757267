import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {MenuItem, MessageService} from "primeng/api";
import {Util} from "../../controller/Util";
import {NetworkService} from "../../services/network.service";

@Component({
    selector: 'app-conciliation',
    templateUrl: './conciliation.component.html',
    styleUrls: ['./conciliation.component.css']
})
export class ConciliationComponent implements OnInit {

    itemsTabMenu = [
        {label: 'Todos', icon: 'fa fa-fw fa-bar-chart'},
        {label: 'Conciliados', icon: 'fa fa-fw fa-bar-chart'},
        {label: 'Conciliador', icon: 'fa fa-fw fa-calendar'},
        {label: 'Não Conciliados', icon: 'fa fa-fw fa-calendar'},
        {label: 'Pendentes', icon: 'fa fa-fw fa-exclamation'},
        {label: 'Lançamentos', icon: 'fa fa-reply'},
    ];

    currentIndex = 0
    activeItem: MenuItem;

    classificacao = '3.07.03'

    tabIndex = 0;

    dataLabel = '';
    id;
    dataInicial;
    dataFinal;
    @Output() dataAlterada = new EventEmitter()

    constructor(private router: Router, private route: ActivatedRoute, private messageService: MessageService, private networkService: NetworkService) { }

    ngOnInit() {
        this.route.paramMap.subscribe(param => {
            this.id = param.get('id')
            this.dataInicial = param.get('dataInicial')
            this.dataFinal = param.get('dataFinal')
        })
        this.atualizaItemSelecionado(window.location.href)
        this.router.events.subscribe((v: any) => {
            if (v instanceof NavigationEnd) {
                this.atualizaItemSelecionado(v)
            }
        })
    }

    onTabChange(event) {
        this.tabIndex = event.index;
    }

    atualizaItemSelecionado(v) {
        if (typeof v === 'object') v = v.url
        if (v.toString().match(/\/extract$/)) {
            this.currentIndex = 0
            this.activeItem = this.itemsTabMenu[0];
        } else if (v.toString().match(/\/reconciled$/)) {
            this.currentIndex = 1
            this.activeItem = this.itemsTabMenu[1];
        } else if (v.toString().match(/\/conciliator$/)) {
            this.currentIndex = 2
            this.activeItem = this.itemsTabMenu[2];
        } else if (v.toString().match(/\/not-reconciled$/)) {
            this.currentIndex = 3
            this.activeItem = this.itemsTabMenu[3];
        } else if (v.toString().match(/\/outstanding$/)) {
            this.currentIndex = 4
            this.activeItem = this.itemsTabMenu[4];
        } else if (v.toString().match(/\/account-launch$/)) {
            this.currentIndex = 5
            this.activeItem = this.itemsTabMenu[5];
        }
    }

    setActiveItem(e) {
        this.currentIndex = this.itemsTabMenu.findIndex(v => v === e.activeItem)
        let r = ''
        
        if (this.currentIndex === 0) r = 'extract'
        if (this.currentIndex === 1) r = 'reconciled'
        if (this.currentIndex === 2) r = 'conciliator'
        if (this.currentIndex === 3) r = 'not-reconciled'
        if (this.currentIndex === 4) r = 'outstanding'
        if (this.currentIndex === 5) r = 'account-launch'

        if (r === 'account-launch'){
             this.router.navigate([`${r}/${this.id}`])
             return
        }
        
        this.router.navigate([`conciliation/${this.id}/${this.dataInicial}/${this.dataFinal}/${r}`])
    }

    processarConciliacao() {
        // this.networkService.salvarPost(getUrlFinanceiro(), 'fin/processarConciliacao', {IdContaCaixa: Number(this.id), DataIni: this.dataInicial, DataFim: this.dataFinal}).subscribe(x => {
        //     this.messageService.add(Util.pushSuccessMsgSemDelay('Conciliações Processadas!'))
        // })
    }

}
