<p-card [header]="'Historicos Memorizados'">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-8">
            <div class="p-inputgroup">
                <input #inputPesquisa appUppercaseNotForm (keypress)="pressionaEnter($event)" pInputText>
                <button pButton type="button" icon="fa fa-search" (click)="carregarLista()"></button>
            </div>
        </div>
        <!-- <div class="p-field p-offset-2 p-col-12 p-md-2">
            <button pButton label="Novo Cadastro" (click)="registration()"></button>            
        </div> -->
    </div>

    <p-table styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
        [paginator]="true" [(rows)]="qtdLinhas" [totalRecords]="totalItens" [value]="lista"
        (onLazyLoad)="lazyLoad($event)" [rowsPerPageOptions]="opcoesLinhas">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th [ngStyle]="{width: '10%'}"> Código do Banco </th>
                <th [ngStyle]="{width: '30%'}"> Historico </th>
                <th [ngStyle]="{width: '20%'}"> Natureza </th>
                <th [ngStyle]="{width: '20%'}"> Pessoa </th>
                <th [ngStyle]="{width: '3%'}"> Ações </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>{{rowData.BankCode}}</td>
                <td>{{rowData.Historic}}</td>
                <td>{{rowData.FinancialCategoryId ? rowData.FinancialCategoryId.Description : ''}}</td>
                <td>{{rowData.PersonId ? rowData.PersonId.Nome : ''}}</td>
                <td>
                    <app-opcoes-table [value]="rowData" [opcoes]="opcoesTable"></app-opcoes-table>
                </td>
            </tr>
        </ng-template>
    </p-table>

</p-card>

<p-confirmDialog header="Confirmacao" icon="pi pi-exclamation-triangle" width="425"></p-confirmDialog>
<p-toast position="bottom-right"></p-toast>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #registrationKeyWord></button>
</div>

<app-modal [styleScroll]="{width: '100%', height: '300px'}" [fundo]="true"
    *appModalOpenOnClick="[registrationKeyWord, '1Acd5WGj76']" [hash]="'1Acd5WGj76'">
    <app-modal-key-word-registration (closeModal)="carregarLista()" #content [hash]="'1Acd5WGj76'">
    </app-modal-key-word-registration>
</app-modal>

<!-- <app-modal-cadastrar-pessoa [modalVisible]="modalCadastrarPessoa" #content (closeModal)="modalCadastrarPessoa = false"></app-modal-cadastrar-pessoa> -->