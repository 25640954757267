<div [ngClass]="{'center-content': center}">
    <div [ngClass]="{'p-col-12 p-g-nopad': row}">
        <div [ngClass]="gridClass">
            <label [attr.for]="id">{{label}}</label>
            <input [ngClass]="{'invalid': campoInvalido}" [type]="type" [id]="id" [placeholder]="placeholder" [readOnly]="!!isReadOnly" [(ngModel)]="value"
                  (blur)="aplicarMascara($event)" (change)="change($event)" autocomplete="off" pInputText/>
        </div>
    </div>
</div>

