<app-page-header [urlName]="'Conta'" [secondaryUrlName]="'Editar'">
</app-page-header>
<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>
    <div [formGroup]="form">
        <!-- <app-page-divided> -->
            <p-card>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-2">
                        <app-date [label]="'Data Fechamento'" formControlName="DateRegister"></app-date>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <app-date [label]="'Data Atualização'" formControlName="DateUpdate"></app-date>
                    </div>
                    <div class="p-offset-6 p-field p-col-12 p-md-2">
                        <app-input-money [label]="'Limite de Crédito'" formControlName="CreditLimit"></app-input-money>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
                        <app-input [label]="'Nome'" formControlName="Name"></app-input>
                    </div>
                    <div class="p-field p-col-12 p-md-1">
                        <app-input [label]="'Agência'" formControlName="BankAgency"></app-input>
                    </div>
                    <div class="p-field p-col-2 p-md-2">
                        <app-input [label]="'Conta'" formControlName="AccountNumber"></app-input>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <app-input [label]="'Digito'" formControlName="DigitNumber"></app-input>
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <app-input-integer [label]="'Codigo de Plano de Conta'" formControlName="CodePlanAccount">
                        </app-input-integer>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
                        <app-select [label]="'Banco'" [options]="selectbank" formControlName="BankCode"></app-select>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <app-select [label]="'Categoria Financeira'" [options]="selectCategory"
                            formControlName="FinancialCategoryId"></app-select>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <app-select [label]="'Tipo de Conta'" [options]="selectTypePro" formControlName="TypePro">
                        </app-select>
                    </div>
                </div>
                
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12" style="margin-top: 10px;">
                        <app-footer-page [end]="true" [tamanhoConfirma]="'p-col-2'" [tamanhoCancelar]="'p-col-2'"
                            (confirmar)="processarFormulario()" (cancelar)="cancelarLocal()"
                            [labelConfirmar]="'Salvar'">
                        </app-footer-page>
                    </div>
                </div>
            </p-card>
        <!-- </app-page-divided> -->
    </div>

</ng-template>
<p-toast position="bottom-right"></p-toast>