<div class="exception-body notfound-page">
    <div class="exception-type">
        <img src="../../../assets/layout/images/404.svg">
    </div>

    <div class="card exception-panel">
        <i class="pi pi-exclamation-circle"></i>
        <h1>Pagina em Desenvolvimento</h1>
        <div class="exception-detail">Pagina em Desenvolvimento. Volte a página inicial</div>
        <p-button label="Página Inicial" (click)="home()" styleClass="p-button-secondary"></p-button>
    </div>
</div>
