<app-page-header></app-page-header>
<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>
    <app-page-divided [padrao]="false">
        <div principal>
            <p-card>
                <div class="p-col-12 p-fluid" style="margin-top: -20px;">
                    <div class="p-col-2">
                        <h5>Valor a ser Conciliado: &nbsp;&nbsp; <span
                                [ngClass]="colorValue(data !== undefined ? data.Valor : 0)">{{data !== undefined ?
                                data.Valor : 0 | virgulaPipe}}</span></h5>
                    </div>
                    <div class="p-col-2">
                        <h5>Data: &nbsp;&nbsp; <span>{{data.DataExtrato | date}}</span></h5>
                    </div>
                    <div class="p-col-3">
                        <h5>Histórico: &nbsp;&nbsp; <span>{{data.HistoricoBanco}}</span></h5>
                    </div>
                    <div class="p-col-offset-1 p-col-4">
                        <h5>Valor Selecionado: &nbsp;&nbsp; <span
                                [ngClass]="colorValue(valorSelecionado)">{{valorSelecionado | virgulaPipe}}</span></h5>
                    </div>
                </div>
                <div class="p-col-12 p-fluid" style="margin-top: -20px;">
                    <div class="p-col-2">
                        <app-input-money [label]="'Valor a ser Pesquisado'" (onBlur)="carregarLista()"
                            (keypress)="pressionaEnter($event)" [(ngModel)]="valor"></app-input-money>
                    </div>
                    <p-pickList [source]="lista" [target]="selected" sourceHeader="Selecione"
                        targetHeader="Selecionados" [responsive]="true" filterBy="Historico" dragdrop="true"
                        sourceFilterPlaceholder="Procure pelo histórico"
                        targetFilterPlaceholder="Procure pelo histórico" [sourceStyle]="{'height':'300px'}"
                        [targetStyle]="{'height':'300px'}" [showSourceControls]="false" [showTargetControls]="false"
                        [draggable]="true" (onMoveToSource)="recalcularValorSelecionado()"
                        (onMoveToTarget)="recalcularValorSelecionado()"
                        (onMoveAllToSource)="recalcularValorSelecionado()"
                        (onMoveAllToTarget)="recalcularValorSelecionado()" [targetTrackBy]="trackTarget">
                        <ng-template let-rowData pTemplate="item">
                            <div class="ui-helper-clearfix">
                                <div style="margin-top: 5px;"><span
                                        style="font-weight: bold; display: inline-block; width: 80px;">Histórico:
                                    </span><span>{{rowData.Historico}}</span></div>
                            </div>
                            <div style="margin-top: 5px;"><span
                                    style="font-weight: bold; display: inline-block; width: 80px;">Data:
                                </span><span>{{rowData.Data | date}}</span></div>
                            <div style="margin-top: 5px;"><span
                                    style="font-weight: bold; display: inline-block; width: 80px;">Valor: </span><span
                                    [ngClass]="colorValue(rowData.Valor)">{{rowData.Valor | virgulaPipe}}</span></div>
                        </ng-template>
                    </p-pickList>

                </div>
                &nbsp;
            </p-card>

            <app-footer-page [end]="true" (confirmar)="processarFormulario()" (cancelar)="cancelarLocal()">
            </app-footer-page>

        </div>
    </app-page-divided>
</ng-template>