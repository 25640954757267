<p-dialog [modal]="true" [responsive]="true" [contentStyle]="{'width':'800px', 'overflow':'visible'}"
    [maximizable]="false" [header]="'Nova Categoria Financeira'" [(visible)]="modalVisible" (onHide)="fecharModal()">
    <p-card [formGroup]="form">
        <div *ngIf="primeiraEtapa" class="p-col p-fluid">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12" formGroupName="aux">
                    <app-select (onValueChange)="setarClassificacao($event)" label="Natureza Superior"
                        [options]="selectListaNatureza" [(ngModel)]="natureza" formControlName="naturezaSuperior"></app-select>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid" style="margin-top: 10px;">
                <div class="p-col-12">
                    <app-input [label]="'Descrição'" formControlName="Description"></app-input>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid" style="margin-top: 10px;">
                <div class="p-field p-col-2 p-md-2" style="margin-left: 6px; margin-top: 25px;">
                    <p-checkbox label="Sintético" formControlName="Sintetic"></p-checkbox>
                </div>
                <div class="p-field p-col-3 p-md-3" style="margin-top: 25px; ">
                    <p-checkbox label="Requer Complemento" formControlName="RequireComplement"></p-checkbox>
                </div>
                <div class="p-col-3">
                    <app-select [label]="'Nivel'" [options]="selectLevel" formControlName="Level"></app-select>
                </div>
                <div class="p-col-3">
                    <app-select [label]="'Tipo de Fluxo'" [options]="selectSpecieFlow" formControlName="SpecieFlow">
                    </app-select>
                </div>
            </div>
            &nbsp;
            <div style="display: flex;align-items: flex-end;justify-content: flex-end;" class="p-col-6 p-offset-6">
                <div class="p-col-4">
                    <button class="p-button-danger" pButton type="button" label="Cancelar"
                        (click)="fecharModal()"></button>
                </div>
                <div class="p-col-4">
                    <button pButton type="button" [label]="'Avançar'" (click)="avancarSegundaEtapa()"></button>
                </div>
            </div>
        </div>


        <div *ngIf="segundaEtapa" class="p-col p-fluid">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-9 p-col-nopad">
                    <app-input [label]="'Classificação'" formControlName="Classificate"></app-input>
                </div>
                <div class="p-col-3" style="margin-top: 18px;">
                    <!-- (clickOutside)="gerarClassificacao()" -->
                    <button pButton label="Calcular Classificação" (click)="gerarClassificacao()"></button>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-6">
                    <app-select [label]="'Tipo'" formControlName="Specie" [options]="selectSpecie"></app-select>
                </div>
                <div class="p-col-6">
                    <app-select label="Grupo de Natureza" [options]="selectGrupoNatureza"
                        formControlName="IdNaturezaFinGrupo"></app-select>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12">
                    <app-input [label]="'Historico'" formControlName="Historic"></app-input>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12">
                    <app-select label="Centro de Custo" [options]="selectCostCenter" formControlName="CostCenterId">
                    </app-select>
                </div>
                <div class="p-col-12">
                    <app-select label="Projeto" [options]="selectProject" formControlName="ProjecId"></app-select>
                </div>
            </div>
        </div>

        <app-footer-page *ngIf="!primeiraEtapa" [end]="true" [tamanhoConfirma]="'p-col-3'" [tamanhoCancelar]="'p-col-3'"
            (confirmar)="processarFormulario()" (cancelar)="fecharModal()" [labelConfirmar]="'Salvar'">
        </app-footer-page>
    </p-card>


    <!-- <p-card *ngIf="include" [formGroup]="form">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12">
                <app-select label="Centro de Custo" [options]="selectCostCenter" formControlName="CostCenterId">
                </app-select>
            </div>
            <div class="p-col-12">
                <app-select label="Projeto" [options]="selectProject" formControlName="ProjecId"></app-select>
            </div>
            <div class="p-col-9 p-col-nopad">
                <app-input [label]="'Classificação'" formControlName="Classificate"></app-input>
            </div>
            <div class="p-col-3" style="margin-top: 18px;">                
                <button pButton label="Calcular Classificação" (click)="gerarClassificacao()"></button>
            </div>
            <div class="p-col-12">
                <app-input [label]="'Descrição'" formControlName="Description"></app-input>
            </div>
        </div>

        <app-footer-page [end]="true" [tamanhoConfirma]="'p-col-3'" [tamanhoCancelar]="'p-col-3'"
            (confirmar)="processarFormulario()" (cancelar)="fecharModal()" [labelConfirmar]="'Salvar'">
        </app-footer-page>
    </p-card> -->
</p-dialog>