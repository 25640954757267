<div class="p-fluid p-formgrid p-grid">
    <div class="p-col-1">
    </div>
    <div class="p-col-1 p-offset-8" style="font-size: 18px; margin-top: 5px; margin-right: -20px;">
        <i class="pi pi-file-excel" style="font-size: 2rem; cursor: pointer; margin-right: 20px;"
            (click)="report('xls')"></i>
        <i class="pi pi-file-pdf" style="font-size: 2rem; cursor: pointer" (click)="report('pdf')"></i>
    </div>
    <div class="p-col-2">
        <button pButton label="Processar Conciliação" (click)="processConciliation()"></button>
    </div>
</div>
<div class="p-col p-fluid">
    <p-table styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
        [paginator]="true" dataKey="Id" [(rows)]="qtdLinhas" [totalRecords]="totalItens" [value]="extratoContaBanco"
        (onLazyLoad)="lazyLoad($event)" [lazy]="true" [rowsPerPageOptions]="opcoesLinhas">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 12%;" pSortableColumn="DateMovement">Data do Movemto
                    <p-sortIcon field="DateMovement"></p-sortIcon>
                </th>
                <th style="width: 50%;" pSortableColumn="Historic">Histórico
                    <p-sortIcon field="Historic"></p-sortIcon>
                </th>
                <th style="width: 10%;" pSortableColumn="Amount">Valor
                    <p-sortIcon field="Amount"></p-sortIcon>
                </th>
                <th style="width: 10%;" pSortableColumn="Reconciled">Conciliado
                    <p-sortIcon field="Reconciled"></p-sortIcon>
                </th>
                <th style="width: 5%;"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td appAlignCenter>{{rowData.DateMovement | date}}</td>
                <td>{{rowData.Historic}}</td>
                <td appAlinharDireita [ngClass]="colorValue(rowData.Amount)">{{rowData.Amount | virgulaPipe}}</td>
                <td>{{status(rowData.Reconciled)}}</td>
                <td>
                    <app-opcoes-table [value]="rowData" [opcoes]="itemsRow"></app-opcoes-table>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<p-confirmDialog header="Confirmacao" icon="pi pi-exclamation-triangle" width="425"></p-confirmDialog>