<p-dialog [modal]="true" [responsive]="true" [contentStyle]="{'max-width':'800px'}" [maximizable]="true"
          [header]="'Pesquisar Categoria Financeira'" [(visible)]="modalVisible" (onHide)="fecharModal()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12">
            <div class="p-inputgroup" style="margin-bottom: 10px;">
                <input #inputPesquisa appUppercaseNotForm (keypress)="pressionaEnter($event)" pInputText>
                <button style="width: 30px;" pButton type="button" icon="fa fa-search" (click)="carregarLista()"
                        class="ui-button-warn"></button>
            </div>
            &nbsp;
            <p-table [(selection)]="value" selectionMode="single" [paginator]="true" [rows]="qtdLinhas" [totalRecords]="totalItens" [value]="lista" *ngIf="modalVisible" #p [lazy]="true" (onLazyLoad)="lazyLoad($event)">

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th [ngStyle]="{width: '80%'}"> Descrição </th>
                        <th [ngStyle]="{width: '20%'}"> Cod Controle </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">
                        <td>{{rowData.Description}}</td>
                        <td>{{rowData.CodeControl}}</td>
                    </tr>
                </ng-template>

            </p-table>
            &nbsp;

            <p-footer>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-offset-8 p-col-2">
                        <button pButton type="button" label="Cancelar" style="margin-right:10px" class="p-button-danger" (click)="fecharModal()"></button>
                    </div>
                    <div class="p-col-2">
                        <button pButton type="button" label="Confirmar" (click)="confirmar()"></button>
                    </div>
                </div>
            </p-footer>

        </div>
        &nbsp;
    </div>
</p-dialog>
