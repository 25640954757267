<p-dialog [modal]="true" [responsive]="true" [contentStyle]="{'width':'800px', 'overflow':'visible'}"
    [maximizable]="false" [header]="'Cadastro de Pessoa'" [(visible)]="modalVisible" (onHide)="fecharModal()">       
    <p-card [formGroup]="form">
        <div *ngIf="primeiraEtapa" class="p-fluid p-formgrid p-grid" formGroupName="PessoaForm">
            <div class="p-field p-col-9">
                <app-input-cpf-cnpj primeiro [label]="'Cpf / Cnpj'" formControlName="CpfCnpj" [row]="true"
                   ></app-input-cpf-cnpj>
            </div>
            <div class="p-field p-col-3" style="margin-top: 15px;">
                <button pButton label="Avançar" (click)="avancar()"></button>
            </div>
        </div>
        <div *ngIf="!primeiraEtapa" >
            <div class="p-fluid p-formgrid p-grid" formGroupName="PessoaForm">
                <div class="p-field p-col-12">
                    <app-input [label]="'Nome ou Razão Social'" formControlName="Nome"></app-input>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid" formGroupName="PessoaForm">
                <div class="p-field p-col-6">
                    <app-input [label]="'Email'" formControlName="Email"></app-input>
                </div>
                <div class="p-field p-col-6">
                    <app-input [label]="'Contato'" formControlName="Contato"></app-input>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-6" formGroupName="PessoaForm">
                    <app-input-telefone [label]="'Celular'" formControlName="Celular"></app-input-telefone>
                </div>
                <div class="p-field p-col-6">
                    <app-select [label]="'Categoria Financeira'" [options]="categoria" formControlName="NatureFinancialId"></app-select>
                </div>
            </div>            
            <app-footer-page [end]="true" [tamanhoConfirma]="'p-col-3'" [tamanhoCancelar]="'p-col-3'"
                (confirmar)="processarFormulario()" (cancelar)="fecharModal()" [labelConfirmar]="'Salvar'">
            </app-footer-page> 
        </div>
    </p-card>
    <!-- <div *ngIf="exibirLoader | async" style="position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; z-index: 100; display: flex; align-items: center; justify-content: center; background-color: rgba(255, 255, 255, .4);">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <div *ngIf="exibirLoaderNetwork | async" style="position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; z-index: 100; display: flex; align-items: center; justify-content: center; background-color: rgba(255, 255, 255, .4);">
        <p-progressSpinner></p-progressSpinner>
    </div> -->
</p-dialog>
<p-toast position="bottom-right"></p-toast>