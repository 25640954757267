<ng-template #conteudo>
    <app-page-divided [padrao]="false">
        <div principal [formGroup]="form">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12" style="font-weight: bold; font-size: 18px;">
                    <label>Conta: </label>
                    <span>{{data.Name}}</span>
                    <!-- <app-select [icon]="true" [label]="'Conta'" [options]="selectContaCaixa" formControlName="AccountId"></app-select> -->
                </div>
                <br><br>
                <!-- </div>

            <div class="p-fluid p-formgrid p-grid"> -->
                <div class="p-col-6">
                    <app-date label="Data" formControlName="DateBalance"></app-date>
                </div>
                <div class="p-col-6">
                    <app-input-money label="Valor" formControlName="Balance"></app-input-money>
                </div>
            </div>
            <div style="margin-top: 50px;">
                <app-footer-page [end]="true" (confirmar)="processarFormulario()" (cancelar)="fecharModal()"
                    labelConfirmar="Gravar"></app-footer-page>
            </div>
        </div>
    </app-page-divided>
</ng-template>