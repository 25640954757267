<p-card [header]="'Listagem de Categoria Financeira'">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <div class="p-inputgroup">
                <input #inputPesquisa appUppercaseNotForm (keypress)="filtrarEPesquisar($event)" [(ngModel)]="filtro"
                    pInputText>
                <button pButton type="button" icon="fa fa-search" (click)="loadList()"></button>
            </div>
        </div>
        <!-- <div class="p-field p-col-12 p-md-2" style="position: relative;">
            <app-select #selectValue [options]="selectSort"></app-select>
        </div> -->
        <div class="p-field p-col-12 p-md-2" style="margin-top: 5px;">
            <i class="pi pi-file-excel" style="font-size: 2rem; margin-left: 95px; cursor: pointer;"
                (click)="report('xls')"></i>
            <i class="pi pi-file-pdf" style="font-size: 2rem; margin-left: 25px; cursor: pointer"
                (click)="report('pdf')"></i>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <button pButton label="Cadastro Simples" (click)="openModalSimpleRegistration()"></button>            
        </div>
        <div class="p-field p-col-12 p-md-2">
            <button pButton label="Nova Categoria" (click)="navegar()"></button>
        </div>
    </div>

    <p-table styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
        [paginator]="true" [(rows)]="qtdLinhas" [totalRecords]="totalItens" [value]="lista" [lazy]="true"
        (onLazyLoad)="lazyLoad($event)" [rowsPerPageOptions]="opcoesLinhas">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th [ngStyle]="{width: '7%'}"> Classificação </th>
                <th [ngStyle]="{width: '40%'}"> Descrição </th>
                <th [ngStyle]="{width: '10%'}"> Cod. Controle </th>
                <th [ngStyle]="{width: '7%'}"> Sintético </th>
                <th [ngStyle]="{width: '10%'}"> Conta Contábil</th>
                <th [ngStyle]="{width: '5%'}"> Ações </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>{{rowData.Classificate}}</td>
                <td>{{rowData.Description}}</td>
                <td>{{rowData.CodeControl}}</td>
                <td>{{rowData.Sintetic}}</td>
                <td>{{rowData.CostCenterId}}</td>
                <td>
                    <app-opcoes-table [value]="rowData" [opcoes]="opcoesTable"></app-opcoes-table>
                </td>
            </tr>
        </ng-template>
    </p-table>

</p-card>

<p-confirmDialog header="Confirmacao" icon="pi pi-exclamation-triangle" width="425"></p-confirmDialog>
<p-toast position="bottom-right"></p-toast>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #simpleRegistration></button>
</div>

<!-- <app-modal [fundo]="true" *appModalOpenOnClick="[cadastrarNatureza, '1Acd5WGj70']" [hash]="'1Acd5WGj70'" [autoOverflow]="true">
    <app-modal-natureza-financeira-cadastro #content></app-modal-natureza-financeira-cadastro>
</app-modal> -->

<app-modal [styleScroll]="{width: '100%', height: '250px'}" [fundo]="true" *appModalOpenOnClick="[simpleRegistration, '1Acd5WGj40']" [hash]="'1Acd5WGj40'">
    <app-modal-simple-registration  #content></app-modal-simple-registration>
</app-modal>

<app-modal-natureza-financeira-cadastro [data]="natureza" [include]="include" [modalVisible]="modalCadastrarNatureza"
    #content (closeModal)="conclude()"></app-modal-natureza-financeira-cadastro>