<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>
    <div [formGroup]="form">
        <p-card>
            <div class="p-fluid p-formgrid p-grid" formGroupName="PessoaForm">
                <div class="p-col-4">
                    <app-input-cpf-cnpj [label]="'Cpf'" formControlName="CpfCnpj"></app-input-cpf-cnpj>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-6" formGroupName="PessoaForm">
                    <app-input [label]="'Nome'" formControlName="Nome"></app-input>
                </div>
                <div class="p-col-6">
                    <app-input [label]="'Nome Reduzido'" formControlName="SimpleName"></app-input>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-6">
                    <app-input [label]="'Email'" formControlName="Email"></app-input>
                </div>
                <div class="p-col-6">
                    <app-select [label]="'Tipo de Usuário'" [options]="selectType" formControlName="TypeUser" (onValueChange)="selectedType($event)"></app-select>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-6">
                    <app-input-telefone [label]="'Celular'" formControlName="Celular"></app-input-telefone>
                </div>
                <div *ngIf="typeAccounting === 'C'" class="p-col-6" formGroupName="aux">
                    <app-select [label]="'Perfil'" [options]="selectPerfil" formControlName="Perfil"></app-select>
                </div>
            </div>
            <div class="p-offset-7 p-col-5">
                <app-footer-page *ngIf="!modal" (cancelar)="cancelarLocal()" (confirmar)="processarFormulario()">
                </app-footer-page>
            </div>
            &nbsp;

        </p-card>
    </div>
    <!-- </app-page-divided>

    </div> -->

</ng-template>
<p-toast position="bottom-right"></p-toast>