<p-card [header]="'Demostrativo do Resultado do Exercicio'">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-formgrid p-grid p-col-4">
            <div class="p-col-5">
                <app-date #dataInicial [(ngModel)]="dataInit"></app-date>
            </div>
            <div class="p-col-5">
                <app-date #dataFinal [(ngModel)]="dataFim"></app-date>
            </div>
        </div>
        <div class="p-col-2" style="margin-top: 5px; margin-left: -65px;">
            <app-date-navigator (alterouData)="alterouData($event)"></app-date-navigator>
        </div>
        <!-- <div class="p-field p-col-12 p-md-2" style="position: relative;">
            <app-select #selectValue [options]="selectSort"></app-select>
        </div> -->
        <div class="p-field p-col-12 p-md-2" style="margin-top: 5px;">
            <i class="pi pi-file-excel" style="font-size: 2rem; margin-left: 95px; cursor: pointer;"
                (click)="report('xls')"></i>
            <i class="pi pi-file-pdf" style="font-size: 2rem; margin-left: 25px; cursor: pointer"
                (click)="report('pdf')"></i>
        </div>
        <div class="p-offset-2 p-field p-col-12 p-md-2">
            <p-splitButton label="Relatórios" [model]="itemsReport"></p-splitButton>
        </div>
    </div>
</p-card>

<p-confirmDialog header="Confirmacao" icon="pi pi-exclamation-triangle" width="425"></p-confirmDialog>
<p-toast position="bottom-right"></p-toast>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #simpleRegistration></button>
</div>
&nbsp;
<div class="p-col-12">
    <div class="p-offset-2 p-col-6">
        <p-card>
            <!-- <div class="vertical">
                
            </div> -->
            <div class="p-col-12">
                <div class="card-resumo-item">
                    <div class="p-col-6"></div>
                    <div appAlignCenter class="p-col-3">
                        <span>M2</span>
                    </div>
                    <div appAlignCenter class="p-col-3">
                        <span>%</span>
                    </div>
                </div>

                <div class="card-resumo-item">
                    <div class="p-col-6">
                        <span>Receitas Recebidas</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(Revenue)">{{Revenue | virgulaPipe}}</span>
                    </div>
                    <div class="p-col-3"></div>                    
                </div>

                 <div class="card-item">
                    <div class="p-col-6">
                        <span> (-) Dedução da Receita</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(Deductions)">{{Deductions | virgulaPipe}}</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span>{{PercentageDeductions}} %</span>
                    </div>                    
                </div>

                <hr />

                <div class="card-resumo-item">
                    <div class="p-col-6">
                        <span>=Receita Liquida</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(NetRevenue)">{{NetRevenue | virgulaPipe}}</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span>{{PercentageNetRevenue}} %</span>
                    </div>                    
                </div>

                <div class="card-item">
                    <div class="p-col-6">
                        <span> (-) Custo das Vendas</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(CostOfSold)">{{CostOfSold | virgulaPipe}}</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span>{{PercentageCostOfSold}} %</span>
                    </div>                    
                </div>

                <div class="card-item">
                    <div class="p-col-6">
                        <span> (-) Despesas Variáveis de venda</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(VariableExpense)">{{VariableExpense | virgulaPipe}}</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span>{{PercentageVariableExpense}} %</span>
                    </div>
                </div>

                <hr />

                <div class="card-resumo-item">
                    <div class="p-col-6">
                        <span>=Lucro Bruto</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(GrossProfit)">{{GrossProfit | virgulaPipe}}</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span>{{PercentageGrossProfit}} %</span>
                    </div>                    
                </div>

                <div class="card-item">
                    <div class="p-col-6">
                        <span> (-) Despesas Operacionais</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(OperationalExpense)">{{OperationalExpense | virgulaPipe}}</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span>{{PercentageOperationalExpense}} %</span>
                    </div>                    
                </div>

                <div class="card-item">
                    <div class="p-col-6">
                        <span> (-) Despesas com Sócios</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(PartnerExpense)">{{PartnerExpense | virgulaPipe}}</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span>{{PercentagePartnerExpense}} %</span>
                    </div>
                </div>

                <hr />

                <div class="card-resumo-item">
                    <div class="p-col-6">
                        <span>=Lucro Liquido</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(NetProfit)">{{NetProfit | virgulaPipe}}</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span>{{PercentageNetProfit}} %</span>
                    </div>                    
                </div>

                <hr />

                <div class="card-item">
                    <div class="p-col-6">
                        <span> Ponto de Equilibrio</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(BreakEven)">{{BreakEven | virgulaPipe}}</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span>{{PercentageBreakEven}} %</span>
                    </div>                    
                </div>

                <div class="card-item">
                    <div class="p-col-6">
                        <span> Ebitda</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span [ngClass]="colorValue(Ebitda)">{{Ebitda | virgulaPipe}}</span>
                    </div>
                    <div *ngIf="this.data" appAlignRight class="p-col-3">
                        <span>{{PercentageEbitda}} %</span>
                    </div>
                </div>

                <hr />

            </div>
           
        </p-card>
    </div>
</div>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #modalreportdre></button>
</div>


<app-modal [styleScroll]="{width: '100%', height: '150px'}" [fundo]="true" *appModalOpenOnClick="[modalreportdre, '1Acd5WGj8']" [hash]="'1Acd5WGj8'">
    <app-modal-report-dre #content [dateStart]="dateStart" [dateEnd]="dateEnd" [endpoint]="endpoint" [nameFile]="nameFile" [title]="title" [hash]="'1Acd5WGj8'"></app-modal-report-dre>
</app-modal>