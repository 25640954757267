<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>
    <div [formGroup]="form">
        <p-card>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <app-input [label]="'Descrição'" formControlName="Description"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <app-input [label]="'Classificação'" formControlName="Classificate"></app-input>
                </div>
                <div class="p-col-2" style="margin-top: 18px;">
                    <button pButton label="Calcular Classificação" (click)="gerarClassificacao()"></button>
                </div>
                <div class="p-field p-col-2 p-md-2" style="margin-top: 25px;">

                    <!-- <div>
                        <span>Ativo</span>
                    </div>
                    <div>
                        <p-toggleButton [style]="{'width':'100px'}" [onLabel]="onLabel" [offLabel]="offLabel"
                            formControlName="Sintetic">
                        </p-toggleButton>
                    </div> -->
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-3">
                    <app-input [label]="'Cod. Plano de Contas'" formControlName="CodeAccountPlan"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <app-input [label]="'Descrição Reduzida'" formControlName="ShortDescription"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <app-select label="Grupo de Natureza" [options]="selectGrupoNatureza"
                        formControlName="IdNaturezaFinGrupo"></app-select>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <app-input [label]="'Historico'" formControlName="Historic"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <app-select [label]="'Tipo'" formControlName="Specie" [options]="selectSpecie"></app-select>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <app-input [label]="'Codigo de Controle'" formControlName="CodeControl"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <app-input [label]="'Codigo de Integração'" formControlName="CodeIntegration"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <app-select [label]="'Fixo / Variavel'" [options]="selectFixVariable" formControlName="FixVariable"></app-select>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-2 p-md-2" style="margin-top: 25px; margin-left: 5px;">
                    <p-checkbox label="Exibir no APP" binary="true" formControlName="ViewOnApp"></p-checkbox>
                </div>
                <div class="p-field p-col-2 p-md-2" style="margin-top: 25px;">
                    <p-checkbox label="Sintético" binary="true" formControlName="Sintetic"></p-checkbox>
                </div>
                <div class="p-field p-col-2 p-md-2" style="margin-top: 25px;">
                    <p-checkbox label="Requer Complemento" binary="true" formControlName="RequireComplement"></p-checkbox>
                </div>                           
                <div class="p-col-2" style="margin-left: -5px;">
                    <app-select [label]="'Tipo de Fluxo'" [options]="selectSpecieFlow" formControlName="SpecieFlow">
                    </app-select>
                </div>
                <div class="p-col-2">
                    <app-select [label]="'Nivel'" [options]="selectLevel" formControlName="Level"></app-select>
                </div>
            </div>
            &nbsp;
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12" style="margin-top: 10px;">
                    <app-footer-page [end]="true" [tamanhoConfirma]="'p-col-2'" [tamanhoCancelar]="'p-col-2'"
                        (confirmar)="processarFormulario()" (cancelar)="cancelarLocal()" [labelConfirmar]="'Salvar'">
                    </app-footer-page>
                </div>
            </div>
        </p-card>
    </div>

</ng-template>
<p-toast position="bottom-right"></p-toast>