<p-card [header]="'Departamentos'">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-8">
            <div class="p-inputgroup">
                <input #inputPesquisa appUppercaseNotForm (keypress)="pressionaEnter($event)" pInputText>
                <button pButton type="button" icon="fa fa-search" (click)="carregarLista()"
                        ></button>
            </div>
        </div>        
        <div class="p-field p-offset-2 p-col-12 p-md-2">
            <button pButton label="Cadastrar" (click)="registration()"></button>
            
        </div>
    </div>

    <p-table styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm" [paginator]="true" [(rows)]="qtdLinhas" [totalRecords]="totalItens" [value]="lista"  (onLazyLoad)="lazyLoad($event)" [rowsPerPageOptions]="opcoesLinhas">
        <ng-template pTemplate="header" let-columns>
            <tr>
            <th [ngStyle]="{width: '40%'}"> Departamento </th>            
            <th [ngStyle]="{width: '10%'}"> Interno </th>
            <th [ngStyle]="{width: '10%'}"> Ativo </th>
            <th [ngStyle]="{width: '5%'}"> Ações </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
            <td><span (click)="linkPessoa(rowData)" class="link-pessoa">{{rowData.Description}}</span></td>
            <td appAlignCenter>{{getActive(rowData.Interno)}}</td>
            <td appAlignCenter>{{getActive(rowData.Active)}}</td>
            <td><app-opcoes-table [value]="rowData"
                                  [opcoes]="opcoesTable"></app-opcoes-table></td>
            </tr>
        </ng-template>
    </p-table>

</p-card>

<p-confirmDialog header="Confirmacao" icon="pi pi-exclamation-triangle" width="425"></p-confirmDialog>
<p-toast position="bottom-right"></p-toast>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #registrationDepartments></button>
</div>

<app-modal [fundo]="true" *appModalOpenOnClick="[registrationDepartments, '1Acd5WGj70']" [hash]="'1Acd5WGj70'">
    <app-modal-departments-registration #content [hash]="'1Acd5WGj70'"></app-modal-departments-registration>
</app-modal>

<!-- <app-modal-cadastrar-pessoa [modalVisible]="modalCadastrarPessoa" #content (closeModal)="modalCadastrarPessoa = false"></app-modal-cadastrar-pessoa> -->