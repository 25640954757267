<div class="p-col p-fluid">
    <div style="width: 100%; background-color: #EAEFF2; position: fixed; top: 70px; left: 0; z-index: 50;">
    <div class="p-offset-1 p-col-10">
        <p-tabMenu #tab [model]="itemsTabMenu" [activeItem]="activeItem" (click)="setActiveItem(tab)">
            <ng-template pTemplate="item" let-item let-i="index">
                <div style="position: relative; text-align: center; min-width: 10em">
                    <div class="p-menuitem-icon" [ngClass]="item.icon" *ngIf="item.icon" style="font-size: 2em"></div>
                    <div class="p-menuitem-text" style="margin-top: 5px;">
                        {{item.label}}
                    </div>
                </div>
            </ng-template>
        </p-tabMenu>
    </div>
    </div>

    <div class="p-col-12 p-col-nopad" style="margin-top: 50px;">
    <router-outlet></router-outlet>
    </div>

</div>

