<p-card [header]="'Contas'">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <div class="p-inputgroup">
                <input #inputPesquisa appUppercaseNotForm (keypress)="pressionaEnter($event)" pInputText>
                <button pButton type="button" icon="fa fa-search" (click)="carregarLista()"></button>
            </div>
        </div>
        <div class="p-field p-offset-2 p-col-12 p-md-2" style="margin-top: 4px;">
            <button pButton label="Buscar Extratos" type="button" (click)="updateItens()"></button>
        </div>
        <div class="p-field p-col-12 p-md-2" style="margin-top: 4px;">
            <button pButton label="Nova Conta" (click)="newAccount()"></button>
            <!-- (click)="navegar()" -->
        </div>
    </div>

    <p-table styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
        [paginator]="true" [(rows)]="qtdLinhas" [totalRecords]="totalItens" [value]="lista"
        (onLazyLoad)="lazyLoad($event)" [rowsPerPageOptions]="[7,10,20,30,40,50,60,70,80,90,100]">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th [ngStyle]="{width: '20%'}"> Nome </th>
                <th [ngStyle]="{width: '10%'}"> Saldo </th>
                <th [ngStyle]="{width: '10%'}"> Atualizado em</th>
                <th [ngStyle]="{width: '10%'}"> Tipo </th>
                <th [ngStyle]="{width: '10%'}"> Conta </th>
                <th [ngStyle]="{width: '10%'}"> Agência </th>
                <th [ngStyle]="{width: '3%'}"> Ações </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td><span (click)="linkLaunch(rowData)" class="link-pessoa">{{rowData.Name}}</span></td>
                <td appAlignRight>{{rowData.Balance}}</td>
                <td>{{rowData.DateUpdate | date}}</td>
                <td>{{typeAccount(rowData.TypePro)}}</td>
                <td>{{rowData.AccountNumber}}</td>
                <td>{{rowData.BankAgency}}</td>
                <td>
                    <app-opcoes-table [value]="rowData" [opcoes]="opcoesTable"></app-opcoes-table>
                </td>
            </tr>
        </ng-template>
    </p-table>

</p-card>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #registrationAccount></button>
</div>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #openingbalance></button>
</div>

<!-- <app-modal [fundo]="true" *appModalOpenOnClick="[registrationAccount, '1Acd5WGj70']" [hash]="'1Acd5WGj70'">
    <app-modal-account-registration #content [hash]="'1Acd5WGj70'"></app-modal-account-registration>
</app-modal> -->

<app-modal [styleScroll]="{width: '100%', height: '350px'}" [fundo]="true"
    *appModalOpenOnClick="[registrationAccount, '1Acd5WGj70']" [hash]="'1Acd5WGj70'">
    <app-modal-account-registration #content (closeModal)="carregarLista()" [hash]="'1Acd5WGj70'">
    </app-modal-account-registration>
</app-modal>

<app-modal [styleScroll]="{width: '100%', height: '250px'}" [fundo]="true" *appModalOpenOnClick="[openingbalance, '1Acd5WGj2']" [hash]="'1Acd5WGj2'">
    <app-modal-opening-balance  #content (dadosSalvos)="dadosSalvos()" [data]="data"></app-modal-opening-balance>
</app-modal>

<p-confirmDialog header="Confirmacao" icon="pi pi-exclamation-triangle" width="425"></p-confirmDialog>
<p-toast position="bottom-right"></p-toast>
<!-- <app-modal-cadastrar-pessoa [modalVisible]="modalCadastrarPessoa" #content (closeModal)="modalCadastrarPessoa = false"></app-modal-cadastrar-pessoa> -->
