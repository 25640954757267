
		<ng-container>
			<a [attr.href]="item.url" (click)="itemClick($event)" *ngIf="!item.routerLink || item.items"
               (mouseenter)="onMouseEnter()" (keydown.enter)="itemClick($event)" pRipple
			   [attr.target]="item.target" [attr.tabindex]="0">
				<i *ngIf="item.icon" [ngClass]="item.icon"></i>
				<span>{{item.label}}</span>
				<span class="menuitem-badge" *ngIf="item.badge">{{item.badge}}</span>
				<i class="pi pi-fw pi-angle-down submenu-icon" style="margin-top: 10px;" *ngIf="item.items"></i>
			</a>
			<a (click)="itemClick($event)" (mouseenter)="onMouseEnter()" *ngIf="item.routerLink && !item.items"
			   [routerLink]="item.routerLink" routerLinkActive="active-menuitem-routerlink" pRipple
			   [routerLinkActiveOptions]="{exact: true}" [attr.target]="item.target" [attr.tabindex]="0">
				<i *ngIf="item.icon" [ngClass]="item.icon"></i>
				<span>{{item.label}}</span>
				<span class="menuitem-badge" *ngIf="item.badge">{{item.badge}}</span>
				<i class="pi pi-fw pi-angle-down submenu-icon" style="margin-top: 10px;" *ngIf="item.items"></i>
			</a>
			<div class="layout-menu-tooltip">
				<div class="layout-menu-tooltip-arrow"></div>
				<div class="layout-menu-tooltip-text">{{item.label}}</div>
			</div>
			<ul *ngIf="item.items && active" [@children]="((app.isSlim()||app.isHorizontal()) && root) ? (active ? 'visible' : 'hidden') :
			(active ? 'visibleAnimated' : 'hiddenAnimated')">
				<ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
					<li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
				</ng-template>
			</ul>
		</ng-container>
    