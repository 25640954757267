<app-page-header></app-page-header>
<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>
    <app-page-divided [padrao]="false">
        <div principal class="p-col p-fluid" [formGroup]="form">
            <form  formGroupName="PessoaForm">
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-col-8 p-col-nopad">
                        <app-input-cpf-cnpj segundo label="CPF/CNPJ" (onBlur)="buscaCnpj($event)" formControlName="CpfCnpj"></app-input-cpf-cnpj>
                    </div>
                </div>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-col-12">
                        <app-input [label]="'Nome'" formControlName="Nome"></app-input>
                    </div>
                </div>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-col-6">
                        <app-input [label]="'Email'" formControlName="Email"></app-input>
                    </div>
                    <div class="p-col-6">
                        <app-input [label]="'Celular'" formControlName="Celular"></app-input>
                    </div>
                </div>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-col-2 p-fluid" style="margin-left: 5px;">
                        <label for="cep">Cep</label>
                        <p-inputMask id="cep" (onBlur)="verificaCepValido($event)" primeiro mask="99999-999" formControlName="Cep">
                        </p-inputMask>
                    </div>
                    <div class="p-col-9">
                        <app-input label="Logradouro" formControlName="Logradouro"></app-input>
                    </div>
                </div>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-col-10">
                        <app-input label="Bairro" formControlName="Bairro"></app-input>
                    </div>
                    <div class="p-col-2">
                        <app-input label="Número" formControlName="Numero"></app-input>
                    </div>
                </div>
                <div class="p-col-12 p-fluid p-formgrid p-grid">
                    <div class="p-col-2">
                        <app-input label="UF" [isReadOnly]="true" formControlName="Uf"></app-input>
                    </div>
                    <div class="p-col-10">
                        <app-input label="Cidade" [isReadOnly]="true" formControlName="Cidade"></app-input>
                    </div>
                </div>
                &nbsp;
            </form>
            <app-footer-page *ngIf="!modal" [end]="true" (confirmar)="processarFormulario()" (cancelar)="cancelar()">
            </app-footer-page>

        </div>
    </app-page-divided>
</ng-template>