<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>
    <div [formGroup]="form">
        <p-card [header]="'Parametros da Contabilidade'">
            <!-- <div style="margin-left:15px;" class="p-fluid p-formgrid p-grid">
                <h1 style="font-size: 18px;margin-right:10px;">Parametros da Contabilidade</h1>
            </div> -->
            <div principal class="p-fluid">
                <div style="display: flex; justify-content: center;" class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-4 p-md-4" style="margin-right: 100px;">
                        <app-select [label]="'Departamento Conciliação'" [options]="selectDepartament" formControlName="DeparmentConciliateId"></app-select>
                    </div>
                    <div class="p-field p-col-4 p-md-4">
                        <app-select [label]="'Departamento Contabil'" [options]="selectDepartament"  formControlName="DeparmentAccountingId"></app-select>
                    </div>
                </div>
                <div style="display: flex; justify-content: center;" class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-4 p-md-4" style="margin-right: 100px;">
                        <app-select [label]="'Departamento Fiscal'" [options]="selectDepartament"  formControlName="DeparmentFiscalId"></app-select>
                    </div>
                    <div class="p-field p-col-4 p-md-4">
                        <app-select [label]="'Departamento Pessoal - (RH)'" [options]="selectDepartament"  formControlName="DeparmentRhId"></app-select>
                    </div>
                </div>
                <div style="display: flex; justify-content: center;" class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-4 p-md-4" style="margin-right: 100px;">
                        <app-select [label]="'Departamento Societário'" [options]="selectDepartament"  formControlName="DeparmentCorpoateId"></app-select>
                    </div>
                    <div class="p-field p-col-4 p-md-4">
                        <app-select [label]="'Departamento Comercial'" [options]="selectDepartament"  formControlName="DeparmentCommercialId"></app-select>
                    </div>
                </div>
                <div style="display: flex; justify-content: center;" class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-4 p-md-4" style="margin-right: 100px;">
                        <app-select [label]="'Departamento Adm'" [options]="selectDepartament"  formControlName="DeparmentAdmId"></app-select>
                    </div>
                    <div class="p-field p-col-4 p-md-4">
                        <app-select [label]="'Departamento BPO'" [options]="selectDepartament"  formControlName="DeparmentBPOId"></app-select>
                    </div>
                </div>
                <div style="display: flex; justify-content: center;" class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-4 p-md-4" style="margin-right: 100px;">
                        <app-date [label]="'Último Email'" formControlName="DateLastEmailDoc"></app-date>
                    </div>
                    <div class="p-field p-col-4 p-md-4">
                        <app-date [label]="'Última Mensagem Whatsapp'" formControlName="DateLastZap"></app-date>
                    </div>
                </div>
            </div>
            <div style="display: flex; justify-content: center;" class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-8 p-md-8" style="margin-top: 10px; margin-left: 110px;">
                    <app-footer-page [end]="true" [tamanhoConfirma]="'p-col-2'" [tamanhoCancelar]="'p-col-2'" (confirmar)="processarFormulario()" (cancelar)="cancelarLocal()" [labelConfirmar]="'Salvar'">
                    </app-footer-page>
                </div>
            </div>
        </p-card>
    </div>
</ng-template>
<p-toast position="bottom-right"></p-toast>
