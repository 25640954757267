<p-card [header]="'Listagem de Pessoas'">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-8">
            <div class="p-inputgroup">
                <input #inputPesquisa appUppercaseNotForm (keypress)="pressionaEnter($event)" pInputText>
                <button pButton type="button" icon="fa fa-search" (click)="carregarLista()"></button>
            </div>
        </div>
        <!-- <div class="p-field p-col-12 p-md-2" style="position: relative;">
            <app-select #selectValue [options]="selectSort"></app-select>
        </div> -->
        <div class="p-field p-col-12 p-md-2" style="margin-top: 5px;">
            <i class="pi pi-file-excel" style="font-size: 2rem; margin-left: 25px; cursor: pointer;"></i>
            <i class="pi pi-file-pdf" style="font-size: 2rem; margin-left: 35px; cursor: pointer"></i>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <button pButton label="Novo Cadastro" (click)="navegar()"></button>

        </div>
    </div>

    <p-table styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm" [paginator]="true" dataKey="Id" [(rows)]="qtdLinhas" [totalRecords]="totalItens" [value]="lista" (onLazyLoad)="lazyLoad($event)" [lazy]="true" [rowsPerPageOptions]="opcoesLinhas" >
        <ng-template pTemplate="header" let-columns>
            <tr>
            <th [ngStyle]="{width: '40%'}"> Nome </th>
            <th [ngStyle]="{width: '30%'}"> Fantasia </th>
            <th [ngStyle]="{width: '20%'}"> Cpf / Cnpj </th>
            <th [ngStyle]="{width: '5%'}"> Ações </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
            <td>{{rowData.Nome}}</td>
            <td>{{rowData.Fantasia}}</td>
            <td>{{rowData.CpfCnpj | cpfCnpj}}</td>
            <td><app-opcoes-table [value]="rowData"
                                  [opcoes]="opcoesTable"></app-opcoes-table></td>
            </tr>
        </ng-template>
    </p-table>

</p-card>

<p-confirmDialog header="Confirmacao" icon="pi pi-exclamation-triangle" width="425"></p-confirmDialog>
<p-toast position="bottom-right"></p-toast>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #cadastrarEmpresa></button>
</div>

<!-- <app-modal [fundo]="true" *appModalOpenOnClick="[cadastrarEmpresa, '1Acd5WGj70']" [hash]="'1Acd5WGj70'">
    <app-modal-empresa-cadastro #content></app-modal-empresa-cadastro>
</app-modal> -->
<app-modal-person-registration [modalVisible]="modalCadastrarPessoa" #content (closeModal)="modalCadastrarPessoa = false"></app-modal-person-registration>
<!-- <app-modal-empresa-cadastro [modalVisible]="modalCadastrarPessoa" #content (closeModal)="modalCadastrarPessoa = false"></app-modal-empresa-cadastro> -->
